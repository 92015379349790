'use client';

import Image from 'next/image';
import Link from 'next/link';
import { getCookie } from 'cookies-next';

import { Button } from '@/components/ui';

export default function NotFound() {
  const token = getCookie('accessToken');

  const notLoggedIn = !token || token === undefined || token === 'null';

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="flex w-[766px] items-center justify-between">
        <div className="w-[310px]">
          <Image
            width={310}
            height={400}
            className="h-auto w-full"
            src={`${process.env.NEXT_PUBLIC_BASE_PATH}/images/not-found-image.png`}
            alt="Not Found"
          />
        </div>
        <div className="w-[400px] space-y-3">
          <div className="pb-6">
            <Image
              width={120}
              height={80}
              src={`${process.env.NEXT_PUBLIC_BASE_PATH}/images/brand-logo.png`}
              alt="TenXer"
            />
          </div>

          <h1 className="text-3xl font-bold">
            Oops! the page you were looking for doesn’t exist.
          </h1>
          <ul className="ml-[23px] list-disc text-sm">
            <li> The link you clicked might be broken or outdated.</li>
            <li>You may have typed the URL incorrectly.</li>
            <li>The page might have been moved or deleted.</li>
          </ul>
          <p className="text-sm">
            Don’t worry, you can read a lot in our{' '}
            <Link className="text-primary font-medium" href="/">
              homepage
            </Link>
          </p>

          <div className="pt-8">
            {notLoggedIn ? (
              <div className="flex gap-4">
                <Link href="/">
                  <Button className="rounded-full px-5" size="lg">
                    Create free account
                  </Button>
                </Link>
                <Link href="/">
                  <Button
                    className="rounded-full px-5"
                    size="lg"
                    variant="secondary"
                  >
                    Login
                  </Button>
                </Link>
              </div>
            ) : (
              <div>
                <Link href={`${process.env.NEXT_PUBLIC_USM_UI_HOST_URL}/`}>
                  <Button className="rounded-full px-5" size="lg">
                    Go to home
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export const runtime = 'edge';
